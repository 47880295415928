import styled from "@emotion/styled"

export const EventContentEmpty = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	span {
		font-size: 18px;
		font-weight: 400;
		line-height: 21.6px;
		color: #435363;
	}
`
export const EventContentWrapper = styled.div<{
	bookingStatus: "yellow" | "gray" | "green"
}>`
	background-color: ${(props) =>
		props.bookingStatus === "yellow"
			? "#FFF7E1"
			: props.bookingStatus === "gray"
				? "#E2E2E2"
				: props.bookingStatus === "green"
					? "#D5F7D8"
					: "#FFF7E1"};
	border: ${(props) =>
		props.bookingStatus === "yellow"
			? "1px solid #FFE9A9"
			: props.bookingStatus === "gray"
				? "1px solid #C4C4C4"
				: props.bookingStatus === "green"
					? "1px solid #B6E5BB"
					: "1px solid #FFE9A9"};
	border-radius: 34px;
	color: #435363;
	.custom-event {
		padding: 6px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 6px;
		.containerIcon {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 20px;
			height: 20px;
			svg {
				width: 20px;
				height: 20px;
			}
		}
		.containerContent {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
`

export const DataDisabelWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	background-color: #f6f6f6;
	width: 100%;
	height: 100%;
	flex-direction: column;
	z-index: -1;
	&:hover {
		cursor: not-allowed;
	}
	> div {
		width: 100%;
		height: 100%;
		color: #6b7280;
		font-size: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`
