import {
	DateSelectArg,
	DateSpanApi,
	DatesSetArg,
	EventInput,
} from "@fullcalendar/core"
import FullCalendar from "@fullcalendar/react"
import interactionPlugin from "@fullcalendar/interaction" // for drag and drop, click, etc.
import resourceTimelinePlugin from "@fullcalendar/resource-timeline" // needed for dayClick
import { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { getCurrentWeekVisibleRange } from "./functions/CurrentWeekVisibleRange"
import { CalendarComponentsWrapper } from "./styles"
import CustomViewProperties from "./components/customViewProperties"
import CustomSearchInput from "./components/customSearchInput"
import PaginationProperties from "./components/paginationProperties"
import EventContent from "./components/eventContent"
import CustomHeader from "./components/customHeader"
import CustomSlotLabelCalendar from "./components/customSlotLabelCalendar"
import dayGridPlugin from "@fullcalendar/daygrid"
import CustomDayCellContent from "./components/customDayCellContent"
import { useGetCalenderData } from "@services"
import { useRecoilState, useRecoilValue } from "recoil"
import {
	loaderState,
	NewCalenderPageCount,
	PopupOverlayNewCalender,
	searchByPropertyName,
	selectedProperty,
	switchReservationsState,
} from "@store/index"
import CustomLoading from "../CustomLoading"
import PopupOverlay from "./components/popupOverlay"
import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import DayHeaderContent from "./components/dayHeaderContent"

dayjs.extend(isSameOrAfter)

export default function NewCalendarComponents() {
	/* ------------------------------- local State ------------------------------ */
	const [isloaderState, setLoaderState] = useRecoilState(loaderState)
	const [isPopupOverlayNewCalender, setPopupOverlayNewCalender] =
		useRecoilState(PopupOverlayNewCalender)
	const pageCount = useRecoilValue(NewCalenderPageCount)
	const [calendarTitle, setCalendarTitle] = useState<string>("")
	const [switchView, setSwitchView] = useState<
		"dayGridMonth" | "resourceTimelineWeek"
	>("dayGridMonth")
	const valueSelectedProperty = useRecoilValue(selectedProperty)
	const searchByPropertyNameValue = useRecoilValue(searchByPropertyName)
	const switchReservationsStateValue = useRecoilValue(switchReservationsState)

	/* ---------------------------------- API'S --------------------------------- */
	const { data: getCalenderData, isLoading } = useGetCalenderData(
		{
			keyword: switchView === "resourceTimelineWeek" ? "week" : undefined,
			page: pageCount,
			filter:
				switchReservationsStateValue === "all"
					? undefined
					: switchReservationsStateValue,
			search:
				switchView === "dayGridMonth" &&
				typeof valueSelectedProperty?.label === "string"
					? valueSelectedProperty.label
					: switchView === "resourceTimelineWeek" &&
						  typeof searchByPropertyNameValue === "string"
						? searchByPropertyNameValue
						: undefined,
		},
		{
			enabled:
				switchView === "dayGridMonth" && valueSelectedProperty?.label
					? true
					: switchView === "resourceTimelineWeek"
						? true
						: false,
		}
	)
	const events: EventInput = useMemo(() => {
		const data = getCalenderData?.result.flatMap((item) => item.dates)
		const eventsList = data?.map((item) => {
			return {
				resourceId: item.propertyId,
				start: item.bookingInfo?.checkIn
					? item.bookingInfo?.checkIn
					: item.date,
				end: item.bookingInfo?.checkOut
					? item.bookingInfo?.checkOut
					: item.date,
				display: !item.bookingInfo?.propertyId ? "background" : null,
				extendedProps: item.bookingInfo
					? {
							date: item.date,
							id: item.bookingInfo?.id,
							propertyId: item.propertyId,
							propertyName: item.bookingInfo?.propertyName,
							channelName: item.bookingInfo?.channelName,
							title: item.bookingInfo?.guestName,
							guestPhoneNumber: item.bookingInfo?.guestPhoneNumber,
							numberOfNights: item.bookingInfo?.numberOfNights,
							totalPrice: item.bookingInfo?.totalPrice,
							bookingStatus: item.bookingInfo?.bookingStatus,
							checkIn: item.bookingInfo?.checkIn,
							checkOut: item.bookingInfo?.checkOut,
							reservationId: item.bookingInfo?.reservationId,
						}
					: {
							date: item.date,
							price: item.airbnb_daily_price,
						},
			}
		})
		return eventsList ?? []
	}, [getCalenderData])

	const disabledDates = useMemo(() => {
		const data = getCalenderData?.result.flatMap((item) => item.dates)
		const getDataFilter = data?.filter(
			(item) =>
				item.availability === "unavailable" && item.availability_type === "busy"
		)
		const daysUnavailable = getDataFilter?.map((item) => item.date)
		return daysUnavailable ?? []
	}, [getCalenderData])

	const resources = useMemo(() => {
		const data = getCalenderData?.result.map((item) => {
			return {
				id: item.propertyId.toString(),
				extendedProps: {
					id: item.propertyId.toString(),
					title: item.propertyName,
					image: item.propertyImage,
				},
			}
		})
		return data ?? []
	}, [getCalenderData])
	/* -------------------------------- Functions ------------------------------- */

	function handleDateSelect(selectInfo: DateSelectArg) {
		setPopupOverlayNewCalender({
			isOpen: true,
			openModule: "advancedSetting",
			from: dayjs(selectInfo.start),
			to: dayjs(selectInfo.end),
		})
	}
	const handleDatesSet = (info: DatesSetArg) => {
		const newTitle = info.view.title // Get the title from the event info
		setCalendarTitle(newTitle) // Update the title state
	}

	const selectAllow = (selectInfo: DateSpanApi) => {
		const isFutureDate = dayjs(selectInfo.start).isSameOrAfter(dayjs(), "day")
		const startDate = dayjs(selectInfo.start).format("YYYY-MM-DD") + "T12:00"
		const selectedCell = events.filter(
			(item: any) =>
				item.extendedProps.checkIn &&
				item.extendedProps.checkOut &&
				dayjs(startDate).isBetween(
					item.extendedProps.checkIn,
					item.extendedProps.checkOut,
					"day",
					"[]"
				)
		)
		const isDateAvailable = selectedCell.length === 0
		return isFutureDate && isDateAvailable
	}
	/* ---------------------------------- Hooks --------------------------------- */
	const calendarRef = useRef<FullCalendar | null>(null)

	const {
		i18n: { language },
	} = useTranslation()

	const refreshCalendar = () => {
		const calendarApi = calendarRef.current?.getApi()
		if (calendarApi) {
			calendarApi.changeView(switchView) // Re-render the calendar view
		}
	}

	useEffect(() => {
		if (switchView) {
			refreshCalendar()
		}
	}, [switchView, refreshCalendar])

	useEffect(() => {
		setLoaderState(isLoading)
	}, [isLoading, setLoaderState])

	return (
		<CalendarComponentsWrapper switchView={switchView}>
			{isloaderState && <CustomLoading />}
			{isPopupOverlayNewCalender?.isOpen && <PopupOverlay />}
			<CustomHeader
				calendarRef={calendarRef}
				switchView={switchView}
				setSwitchView={setSwitchView}
				calendarTitle={calendarTitle}
			/>
			<FullCalendar
				schedulerLicenseKey='0454582308-fcs-1728214716'
				locale={language}
				ref={calendarRef}
				datesSet={handleDatesSet} // Triggered whenever the dates are set (e.g., after navigating)
				timeZone='utc'
				initialDate={dayjs(new Date()).format("YYYY-MM-DD")}
				direction={language === "ar" ? "rtl" : "ltr"}
				plugins={[resourceTimelinePlugin, interactionPlugin, dayGridPlugin]}
				initialView={switchView} // Displays the week in resource timeline
				visibleRange={getCurrentWeekVisibleRange()} // Displays current week's range (Monday-Sunday)
				resources={resources} // all properties
				events={events}
				resourceAreaWidth={200} // Set a fixed width for the resource column
				slotLabelFormat={{ weekday: "long", day: "numeric" }} // Displays days in the header
				slotLabelContent={(info) => <CustomSlotLabelCalendar info={info} />}
				slotDuration='24:00' // Each slot is 1 day
				height='auto'
				headerToolbar={false} // Hide default header
				dayCellContent={(info) => (
					<CustomDayCellContent info={info} disabledDates={disabledDates} />
				)}
				dayHeaderContent={(info) => <DayHeaderContent info={info} />}
				eventContent={(arg) => (
					<EventContent
						disabledDates={disabledDates}
						date={arg.event?.extendedProps?.date}
						display={arg?.event.display as "background" | null}
						cellPrice={arg.event?.extendedProps?.price}
						bookingStatus={
							arg?.event.extendedProps?.bookingStatus as
								| "مؤكد"
								| "new"
								| "cancelled"
								| "ملغي"
						}
						title={arg?.event.extendedProps?.title}
						totalPrice={arg?.event.extendedProps?.totalPrice}
						channelName={
							arg?.event.extendedProps?.channelName as
								| "Airbnb"
								| "GatherIn"
								| "Booking"
								| "Agoda"
								| "Msool"
						}
						checkIn={arg.event.extendedProps.checkIn}
						checkOut={arg.event.extendedProps.checkOut}
						guestPhoneNumber={arg.event.extendedProps.guestPhoneNumber}
						propertyId={arg.event.extendedProps.propertyId}
						propertyName={arg.event.extendedProps.propertyName}
						reservationId={arg.event.extendedProps.reservationId}
					/>
				)} //Event Content
				eventColor='transparent' // Global background color for all events
				resourceAreaColumns={[
					{
						headerContent: () => <CustomSearchInput />, // Custom search input
						cellContent: (arg) => <CustomViewProperties arg={arg} />, // Custom view properties
					},
				]}
				select={handleDateSelect} // Enable selection
				selectable={true}
				selectMirror={true}
				dayMaxEvents={true}
				selectAllow={(selectInfo) => selectAllow(selectInfo)}
			/>
			{switchView === "resourceTimelineWeek" && (
				<PaginationProperties lengthOfRecords={resources.length} />
			)}
		</CalendarComponentsWrapper>
	)
}
