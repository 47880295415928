import { DayCellContentArg } from "@fullcalendar/core"
import { CustomDayCellContentWrapper } from "./styles"
import { useMemo } from "react"
import { SlLock } from "react-icons/sl"

interface IProps {
	info: DayCellContentArg
	disabledDates: string[]
}

export default function CustomDayCellContent({ info, disabledDates }: IProps) {
	const date = info.date.getDate() // Get the day of the month (1-31)
	const today = new Date() // Get today's date

	const isToday =
		date === today.getDate() &&
		info.date.getMonth() === today.getMonth() &&
		info.date.getFullYear() === today.getFullYear()
	/* ---------------------------------- Hooks --------------------------------- */
	const dataDisabel = useMemo(() => {
		if (disabledDates.length === 0 || disabledDates === undefined) return
		const dateStr = info.date.toISOString().split("T")[0]
		return disabledDates.includes(dateStr)
	}, [info, disabledDates])
	return (
		<CustomDayCellContentWrapper $isToday={isToday}>
			<span className='days'>{date}</span>
			{dataDisabel && (
				<div className='dataDisabel'>
					<div>
						<SlLock />
					</div>
				</div>
			)}
		</CustomDayCellContentWrapper>
	)
}
