import { useTranslation } from "react-i18next"
import { AdvancedSettingWrapper } from "./styles"
import CustomButton from "@Common/CustomButton"
import { useSetRecoilState } from "recoil"
import { PopupOverlayNewCalender } from "@store/index"
import PricingTab from "./pricingTab"
import AvailabilityTab from "./availabilityTab"
import { useState } from "react"
import { useGetListingManagement } from "@services"

export default function AdvancedSettings() {
	const [step, setActiveStep] = useState(1)
	const { t } = useTranslation()
	const setPopupOverlayNewCalender = useSetRecoilState(PopupOverlayNewCalender)
	/* ---------------------------------- API'S --------------------------------- */
	const { data: getListingManagement } = useGetListingManagement()
	return (
		<AdvancedSettingWrapper>
			<div className='HeaderSection'>
				<div className='title'>
					<p>{t("CALENDAR.ADVANCEDSETTING")}</p>
				</div>
				<div className='containerButton'>
					<CustomButton
						label={t("SAVE")}
						withBG
						customPadding='7px 21px'
						form={step === 1 ? "pricingForm" : "availabilityForm"}
						type='submit'
					/>
					<CustomButton
						label={t("GENERAL.CANCEL_ALT")}
						customPadding='7px 21px'
						customColor='#E85B5B'
						customBG='#E85B5B17'
						borderColor='#E85B5B17'
						onClick={() => setPopupOverlayNewCalender(undefined)}
					/>
				</div>
			</div>

			<div className='containerTabSection'>
				<button
					type='button'
					className={step === 1 ? "active" : ""}
					onClick={() => setActiveStep(1)}
				>
					{t("COMMON.PRICING")}
				</button>
				<button
					type='button'
					className={step === 2 ? "active" : ""}
					onClick={() => setActiveStep(2)}
				>
					{t("CALENDAR.AVAILABILITY")}
				</button>
			</div>
			{step === 1 && <PricingTab getListingManagement={getListingManagement} />}
			{step === 2 && (
				<AvailabilityTab getListingManagement={getListingManagement} />
			)}
		</AdvancedSettingWrapper>
	)
}
