import CustomDatePicker from "@Common/CustomDatePicker"
import CustomSelect from "@Common/CustomSelect"
import Input from "@Common/input"
import { APIS_KEYS, usePostAvailabilityInChannels } from "@services"
import { loaderState, PopupOverlayNewCalender } from "@store/index"
import dayjs from "dayjs"
import { useContext, useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { AvailabilityTabWrapper } from "./styles"
import { FORM_REGEX_VALIDATORS } from "@utils"
import { IResponseGetListingManagement } from "src/services/calender/getListingManagement/types"
import { useQueryClient } from "@tanstack/react-query"
import { ToastMessageContext } from "@context/toast-message-cotnext"
interface IProps {
	getListingManagement: IResponseGetListingManagement | undefined
}
interface IForm {
	date_from: dayjs.Dayjs | string
	date_to: dayjs.Dayjs | string
	availability: string
	connectedChannels: string
	propertiesId: string
	min_stay: string
}
export default function AvailabilityTab({ getListingManagement }: IProps) {
	/* ------------------------------- Local State ------------------------------ */
	const isPopupOverlayNewCalender = useRecoilValue(PopupOverlayNewCalender)
	const setLoaderState = useSetRecoilState(loaderState)
	const setPopupOverlayNewCalender = useSetRecoilState(PopupOverlayNewCalender)
	const queryClient = useQueryClient()
	const { showSuccess, showError } = useContext(ToastMessageContext)

	/* ---------------------------------- API'S --------------------------------- */
	const { mutateAsync: mutateAsyncAvailabilityInChannels } =
		usePostAvailabilityInChannels()

	/* ---------------------------------- Hooks --------------------------------- */
	const methods = useForm<IForm>()
	const { setValue, watch, register, resetField, reset } = methods
	const date_from_watch = watch("date_from")
	const propertiesId_watch = watch("propertiesId")

	const {
		t,
		i18n: { language },
	} = useTranslation()

	const dataList = useMemo(() => {
		const data = getListingManagement?.result.map((item) => {
			return {
				value: item.propertyId.toString(),
				label: item.propertyName,
			}
		})
		const newData = [
			{
				value: "0",
				label: t("COMMON.All"),
			},
		]?.concat(data ?? [])
		return newData ?? []
	}, [getListingManagement])

	const dataListAvailabilityInChannels = useMemo(() => {
		const dataSelected =
			propertiesId_watch === "0"
				? getListingManagement?.result
				: getListingManagement?.result?.filter(
						(item) => item.propertyId.toString() === propertiesId_watch
					)
		const getConnectedChannels = dataSelected?.flatMap(
			(item) => item.connectedChannels
		)
		const data = getConnectedChannels?.map((item) => {
			return {
				value: item.channelId.toString(),
				label: item.channelName,
			}
		})
		const uniqueData = data?.filter(
			(item, index, self) =>
				index ===
				self.findIndex((t) => t.value === item.value && t.label === item.label)
		)
		const newData = [
			{
				value: "0",
				label: t("COMMON.All"),
			},
		]?.concat(uniqueData ?? [])
		return newData ?? []
	}, [propertiesId_watch])

	/* -------------------------------- Function -------------------------------- */
	const onSubmit = async (data: IForm) => {
		setLoaderState(true)
		const dataListState =
			data.propertiesId === "0"
				? dataList.map((item) => item.value)
				: dataList
						?.filter((item) => item.value === data.propertiesId)
						?.map((sub) => sub.value)

		const dataListAvailabilityInChannelsState =
			data.connectedChannels === "0"
				? dataListAvailabilityInChannels.map((item) => item.value)
				: dataListAvailabilityInChannels
						?.filter((item) => item.value === data.connectedChannels)
						?.map((sub) => sub.value)

		const responseData = {
			propertiesId: dataListState.filter((item) => item !== "0"),
			connectedChannels: dataListAvailabilityInChannelsState.filter(
				(item) => item !== "0"
			),
			categoriesId: [],
			date_from: dayjs(data.date_from).format("YYYY-MM-DD"),
			date_to: dayjs(data.date_to).format("YYYY-MM-DD"),
			availability: data.availability,
			min_stay: data.min_stay,
		}
		const response = await mutateAsyncAvailabilityInChannels({
			...responseData,
		})
		if (response.status === 200) {
			reset()
			setLoaderState(false)
			setPopupOverlayNewCalender(undefined)
			queryClient.invalidateQueries({ queryKey: [APIS_KEYS.GETCALENDERDATA] })
			showSuccess(t("CALENDAR.CLOSE_AVAILABILITY_SUCCESS"))
		}
		if (response.status === 500) {
			response?.data?.errorMessages.map((item: any) => {
				return showError(language === "ar" ? item.messageAr : item.messageEn)
			})
		}
		setLoaderState(false)
	}

	/* ----------------------------- Action on Init ----------------------------- */

	useEffect(() => {
		if (isPopupOverlayNewCalender?.from && isPopupOverlayNewCalender?.to) {
			setValue("date_from", isPopupOverlayNewCalender?.from)
			setValue("date_to", isPopupOverlayNewCalender?.to.subtract(1, "day"))
		}
	}, [isPopupOverlayNewCalender])

	useEffect(() => {
		if (dataList.length > 0) {
			const defaultValue = dataList[0]
			setValue("propertiesId", defaultValue?.value.toString())
		}
	}, [dataList, setValue])

	useEffect(() => {
		if (propertiesId_watch) {
			resetField("connectedChannels")
		}
	}, [propertiesId_watch, setValue])

	return (
		<AvailabilityTabWrapper language={language as "ar" | "en"}>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)} id='availabilityForm'>
					<div className='containerDate'>
						<label>{t("COMMON.DATE")}</label>
						<div className='dateInputs'>
							<CustomDatePicker
								disablePast
								name='date_from'
								label={t("GENERAL.FROM")}
								registerOptions={{
									required: {
										value: true,
										message: t("COMMON.REQUIRED"),
									},
								}}
							/>
							<CustomDatePicker
								disablePast
								name='date_to'
								label={t("GENERAL.TO")}
								registerOptions={{
									required: {
										value: true,
										message: t("COMMON.REQUIRED"),
									},
									min: {
										value: date_from_watch as string,
										message: "",
									},
								}}
							/>
						</div>
					</div>

					<div className='containerRadio'>
						<label>{t("COMMON.AVAILABILITY_STATUS")}</label>
						<div className='containerRadioButtons'>
							<div className='Radio'>
								<input type='radio' {...register("availability")} value='0' />
								<label>{t("CALENDAR.UNAVAILABLE")}</label>
							</div>
							<div className='Radio'>
								<input
									type='radio'
									{...register("availability")}
									value='1'
									defaultChecked
								/>
								<label>{t("CALENDAR.AVAILABLE")}</label>
							</div>
						</div>
					</div>

					<div className='containerMinDays'>
						<CustomSelect
							data={dataList ?? []}
							label={t("COMMON.PROPERTY")}
							name='propertiesId'
							registerOptions={{
								required: {
									value: true,
									message: t("COMMON.REQUIRED"),
								},
							}}
						/>
						<CustomSelect
							data={dataListAvailabilityInChannels ?? []}
							name='connectedChannels'
							label={t("COMMON.CHANNEL")}
							placeholder={t("COMMON.Select_channel")}
							registerOptions={{
								required: {
									value: true,
									message: t("COMMON.REQUIRED"),
								},
							}}
						/>
						<Input
							name='min_stay'
							label={t("CALENDAR.MIN_DAYS")}
							placeholder={t("CALENDAR.MIN_DAYS")}
							type='text'
							suffixLabel={t("COMMON.SATY_NIGHTS_PLACEHOLDER")}
							registerOptions={{
								pattern: {
									value: FORM_REGEX_VALIDATORS.numbersOnly,
									message: t("COMMON.THE_FIELD_ACCEPTS_NUMBERS_ONLY"),
								},
							}}
						/>
					</div>
				</form>
			</FormProvider>
		</AvailabilityTabWrapper>
	)
}
