import FullCalendar from "@fullcalendar/react"
import { MenuItem, Select } from "@mui/material"
import { searchByPropertyName, selectedProperty } from "@store/index"
import { MutableRefObject, SetStateAction, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { IoIosArrowDown } from "react-icons/io"
import { useSetRecoilState } from "recoil"
interface IProps {
	switchView: "dayGridMonth" | "resourceTimelineWeek"
	calendarRef: MutableRefObject<FullCalendar | null>
	setSwitchView: (
		value: SetStateAction<"dayGridMonth" | "resourceTimelineWeek">
	) => void
}
export default function SwitchView({
	switchView,
	calendarRef,
	setSwitchView,
}: IProps) {
	const setSelectedProperty = useSetRecoilState(selectedProperty)
	const searchByPropertyNameState = useSetRecoilState(searchByPropertyName)
	const { t } = useTranslation()
	const data = useMemo(() => {
		const list = [
			{
				value: "dayGridMonth",
				label: t("COMMON.MONTHLY"),
			},
			{
				value: "resourceTimelineWeek",
				label: t("COMMON.WEEKLY"),
			},
		]

		return list ?? []
	}, [])
	return (
		<div className='containerCustomSelectOption'>
			<Select
				defaultValue={switchView}
				onChange={(event) => {
					calendarRef.current?.render()
					setSwitchView(
						event.target.value as "dayGridMonth" | "resourceTimelineWeek"
					)
					setSelectedProperty(undefined)
					searchByPropertyNameState(undefined)
				}}
				IconComponent={IoIosArrowDown}
			>
				{data.map((item, index) => {
					return (
						<MenuItem value={item.value} key={index}>
							{item.label}
						</MenuItem>
					)
				})}
			</Select>
		</div>
	)
}
