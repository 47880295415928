import { atom } from "recoil"
import { ATOMS_KEYS } from "../atom-keys"
import { IConnectedChannel } from "src/services/calender/getListingManagement/types"
interface ISelectedProperty {
	value: number
	label: string
	connectedChannels: IConnectedChannel[]
}
export const selectedProperty = atom<ISelectedProperty | undefined>({
	key: ATOMS_KEYS.SELECTEDPROPERTY,
	default: undefined,
})
