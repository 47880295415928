import {
	DataDisabelWrapper,
	EventContentEmpty,
	EventContentWrapper,
} from "./styles"
import AirbnbIcon from "@assets/svg/airbnb.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import BookingIcon from "@assets/svg/BookingIcon.svg?react"
import ChannelAgoda from "@assets/svg/ChannelAgoda.svg?react"
import MsoolIcon from "@assets/svg/msool-icon.svg?react"
import { useTranslation } from "react-i18next"
import { useSetRecoilState } from "recoil"
import { PopupOverlayNewCalender, reservationDetails } from "@store/index"
import { Fragment } from "react/jsx-runtime"
import { useMemo } from "react"
import { SlLock } from "react-icons/sl"
import { Tooltip } from "@mui/material"

interface IProps {
	channelName: "Airbnb" | "GatherIn" | "Booking" | "Agoda" | "Msool"
	title: string
	totalPrice: string
	bookingStatus: "مؤكد" | "new" | "cancelled" | "ملغي"
	checkIn: string | Date
	checkOut: string | Date
	guestPhoneNumber: string
	propertyId: string
	propertyName: string
	reservationId: string
	display: "background" | null
	cellPrice: string
	disabledDates: string[]
	date: string
}
interface IChannals {
	Airbnb: JSX.Element
	GatherIn: JSX.Element
	Booking: JSX.Element
	Agoda: JSX.Element
	Msool: JSX.Element
}
const channals: IChannals = {
	Airbnb: <AirbnbIcon />,
	GatherIn: <GathernIcon />,
	Booking: <BookingIcon />,
	Agoda: <ChannelAgoda />,
	Msool: <MsoolIcon />,
}

export default function EventContent({
	channelName,
	title,
	bookingStatus,
	totalPrice,
	checkIn,
	checkOut,
	guestPhoneNumber,
	propertyId,
	propertyName,
	reservationId,
	display,
	cellPrice,
	disabledDates,
	date,
}: IProps) {
	const setReservationDetails = useSetRecoilState(reservationDetails)
	const setPopupOverlayNewCalender = useSetRecoilState(PopupOverlayNewCalender)
	const { t } = useTranslation()
	const stylebookingStatus = (bookingStatus: string) => {
		if (bookingStatus === "مؤكد" || bookingStatus === "new") {
			return "green"
		}
		if (bookingStatus === "cancelled" || bookingStatus === "ملغي") {
			return "gray"
		}
		return "yellow"
	}

	const funToGetAllReservationDerails = () => {
		setReservationDetails({
			bookingStatus: bookingStatus,
			channelName: channelName,
			checkIn: checkIn,
			checkOut: checkOut,
			guestName: title,
			guestPhoneNumber: guestPhoneNumber,
			propertyId: propertyId,
			propertyName: propertyName,
			reservationId: reservationId,
			totalPrice: totalPrice,
		})
		setPopupOverlayNewCalender({
			isOpen: true,
			openModule: "reservationDetails",
		})
	}

	const dataDisabel = useMemo(() => {
		if (
			(disabledDates.length === 0 || disabledDates === undefined) &&
			display !== "background"
		)
			return
		const dateStr = date as string
		return disabledDates.includes(dateStr)
	}, [date, disabledDates])

	return (
		<Fragment>
			{display !== "background" && !dataDisabel ? (
				<Tooltip title={title} placement='top' arrow followCursor>
					<EventContentWrapper
						bookingStatus={stylebookingStatus(bookingStatus)}
					>
						<div
							className='custom-event'
							onClick={() => funToGetAllReservationDerails()}
						>
							<span className='containerIcon'>{channals[channelName]}</span>
							<span className='containerContent'>
								{title && title?.substr(0, 10) + "\u2026"}
							</span>
							<span className='containerContent'>
								{totalPrice} {t("GENERAL.SR")}
							</span>
						</div>
					</EventContentWrapper>
				</Tooltip>
			) : display === "background" && dataDisabel ? (
				<DataDisabelWrapper>
					<div>
						<SlLock />
					</div>
				</DataDisabelWrapper>
			) : (
				<EventContentEmpty>
					<span>
						{cellPrice} {t("GENERAL.SR")}
					</span>
				</EventContentEmpty>
			)}
		</Fragment>
	)
}
