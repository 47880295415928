import { ChannelsListActiveWrapper } from "./styles"
import AirbnbIcon from "@assets/svg/airbnb.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import BookingIcon from "@assets/svg/BookingIcon.svg?react"
import ChannelAgoda from "@assets/svg/ChannelAgoda.svg?react"
import MsoolIcon from "@assets/svg/msool-icon.svg?react"
import { useGetAllChannels } from "@services"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"

interface IChannals {
	Airbnb: JSX.Element
	GatherIn: JSX.Element
	Booking: JSX.Element
	Agoda: JSX.Element
	Msool: JSX.Element
}
const channals: IChannals = {
	Airbnb: <AirbnbIcon />,
	GatherIn: <GathernIcon />,
	Booking: <BookingIcon />,
	Agoda: <ChannelAgoda />,
	Msool: <MsoolIcon />,
}
export default function ChannelsListActive() {
	const [isListOpen, setIsListOpen] = useState(true)
	/* ---------------------------------- Hooks --------------------------------- */
	const { t } = useTranslation()
	/* ---------------------------------- API'S --------------------------------- */
	const { data: dataGetAllChannels } = useGetAllChannels()

	/* ------------------------------- Cache Value ------------------------------ */
	const listChannels = useMemo(() => {
		const list = dataGetAllChannels?.result.map((item) => {
			return {
				channelId: item.channelId,
				channelName: item.channelName,
				commission: item.commission,
				status: item.status,
			}
		})

		const activeChannels = list?.filter((item) => item.status === "active")
		return activeChannels ?? []
	}, [dataGetAllChannels])

	return (
		<ChannelsListActiveWrapper>
			<div className='controles' onClick={() => setIsListOpen(!isListOpen)}>
				<span>{t("COMMON.SHOW_CHANNELS")}</span>
				<span>{isListOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}</span>
			</div>
			{isListOpen && (
				<div>
					{listChannels.map((item, index) => {
						return (
							<div key={index} className='containerList'>
								<span className='containerIcon'>
									{
										channals[
											item.channelName as
												| "Airbnb"
												| "GatherIn"
												| "Booking"
												| "Agoda"
												| "Msool"
										]
									}
								</span>
								<span className='channelName'>{item.channelName}</span>
								{" - "}
								<span className='commission'>({item.commission})</span>
							</div>
						)
					})}
				</div>
			)}
		</ChannelsListActiveWrapper>
	)
}
