import styled from "styled-components"

export const ChannelsListActiveWrapper = styled.div`
	.controles {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		cursor: pointer;
		margin-bottom: 12px;
		span {
			color: #435363;
			&:first-child {
				font-size: 12px;
				font-weight: 400;
				line-height: 14.4px;
			}
			&:last-child {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
	.containerList {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 8px;
		color: #435363;
		font-size: 12px;
		font-weight: 400;
		line-height: 17.92px;
		.containerIcon {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 14px;
			height: 14px;
		}
	}
`
