import { atom } from "recoil"
import { ATOMS_KEYS } from "../atom-keys"
import dayjs from "dayjs"

interface ICommonFields {
	isOpen: boolean
	from?: dayjs.Dayjs
	to?: dayjs.Dayjs
}
type IPopupOverlayNewCalender =
	| ({
			openModule: "advancedSetting"
			from: dayjs.Dayjs
			to: dayjs.Dayjs
	  } & ICommonFields)
	| ({
			openModule: "reservationDetails"
	  } & ICommonFields)

export const PopupOverlayNewCalender = atom<
	IPopupOverlayNewCalender | undefined
>({
	key: ATOMS_KEYS.POPUPOVERLAYCALENDER,
	default: undefined,
})
